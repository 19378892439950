.container {

  margin-top: 2.5rem;

  display: flex;

  height: 75%;

  width: 100%;

  flex-direction: column;

  align-items: center;

  padding-left: 1rem;

  padding-right: 1rem;

  .welcomeTxt {

    margin-bottom: 1.25rem
  }

  .welcomeTxt {

    text-align: center
  }

  .welcomeTxt {

    font-size: 1.25rem;

    line-height: 1.75rem
  }

  .welcomeTxt {

    font-weight: 700
  }
  .chooseZoneTxt {

    margin-bottom: 0.5rem
  }
  .chooseZoneTxt {

    width: 100%
  }
  .chooseZoneTxt {

    text-align: left
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy92aWV3cy9zaWduLXVwL2Nob29zZS16b25lL2luZGV4Lm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7O0VBQUEsa0JBQXlEOztFQUF6RCxhQUF5RDs7RUFBekQsV0FBeUQ7O0VBQXpELFdBQXlEOztFQUF6RCxzQkFBeUQ7O0VBQXpELG1CQUF5RDs7RUFBekQsa0JBQXlEOztFQUF6RCxtQkFBeUQ7O0VBR3ZEOztJQUFBO0VBQXlDOztFQUF6Qzs7SUFBQTtFQUF5Qzs7RUFBekM7O0lBQUEsa0JBQXlDOztJQUF6QztFQUF5Qzs7RUFBekM7O0lBQUE7RUFBeUM7RUFHekM7O0lBQUE7RUFBNEI7RUFBNUI7O0lBQUE7RUFBNEI7RUFBNUI7O0lBQUE7RUFBNEI7QUFOMkIiLCJmaWxlIjoic3JjL3ZpZXdzL3NpZ24tdXAvY2hvb3NlLXpvbmUvaW5kZXgubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jb250YWluZXIge1xyXG4gIEBhcHBseSB3LWZ1bGwgaC0zLzQgbXQtMTAgcHgtNCBmbGV4IGZsZXgtY29sIGl0ZW1zLWNlbnRlcjtcclxuXHJcbiAgLndlbGNvbWVUeHQge1xyXG4gICAgQGFwcGx5IGZvbnQtYm9sZCB0ZXh0LWNlbnRlciB0ZXh0LXhsIG1iLTU7XHJcbiAgfVxyXG4gIC5jaG9vc2Vab25lVHh0IHtcclxuICAgIEBhcHBseSB3LWZ1bGwgdGV4dC1sZWZ0IG1iLTI7XHJcbiAgfVxyXG59XHJcbiJdfQ== */